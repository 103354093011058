import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import ForwardRoute from './components/Routes/ForwardRoute'
import { Card, CardBody } from '@windmill/react-ui'

const Layout = lazy(() => import('./containers/Layout'))
const Auth = lazy(() => import('./containers/Auth'))
const Landing = lazy(() => import('./pages/Landing'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))

function DemoInfo({children}) {
  return (
    <div>
      <div className="w-full items-center bg-purple-600">
        <Card colored className="text-white bg-purple-600">
          <CardBody>
            <p>
              This is a demo of the Rocket SaaS Boilerplate. Visit:&nbsp;
              <a href="http://rocketapp.me/">https://rocketapp.me/</a>
            </p>
          </CardBody>
        </Card>
      </div>
      {children}
    </div>
  )
}

function App() {
  return (
    <>
      <DemoInfo>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <ForwardRoute path="/auth" component={Auth} />
            {/* Place new routes over this */}
            <ProtectedRoute path="/app" component={Layout} />
            <Route exact path="/verify-email" component={EmailVerification} />
            <Route exact path="/" component={Landing} />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </DemoInfo>
    </>
  )
}

export default App
